@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4e0d7; /* Adjusted for a more pronounced old paper look */
  color: #333;
}

body {
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    radial-gradient(circle, rgba(0, 0, 0, 0.1) 1%, transparent 1%);
  background-position: -1px -1px, -1px -1px, 0 0;
  background-size: 10px 10px, 10px 10px, 10px 10px;
}
/* App container */
.App {
  text-align: center;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
/* Header styles */
.App-header {
  margin-bottom: 20px;
}

/* Title (Banff Chalet) styles */
h1 {
  font-size: 2.5em;
  font-family: "Cinzel", serif;
  color: #333;
  margin: 20px 0;
}

/* Description box styles, with more intuitive padding and margins */
.description-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px; /* Adjusted for better spacing around the box */
  max-width: 600px;

  box-sizing: border-box; /* Ensures padding doesn't add to the width */
}

/* Description title styles */
.description-title {
  font-size: 1.5em;
  margin-bottom: 15px;
  text-align: left;
}

/* Description text styles */
.description-text {
  text-align: left;
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Button styles */
button {
  font-family: Arial, sans-serif;
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

/* Responsive styles for better mobile view handling */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }
  .description-box {
    padding: 15px; /* Adjusts padding for the description box */
    margin: 10px; /* Reduces margin to ensure space around the box */
    background-size: 20px 20px; /* Adjusts the background pattern size */
  }
  h1 {
    font-size: 2em; /* Adjusted for smaller screens */
  }

  .description-title,
  .description-text {
    margin: 10px 10px; /* Ensures text doesn't touch the edges */
  }

  button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
